import { useEffect } from "react";
import produce from "immer";
import create from "zustand";

const store = create(rawSet =>
{
  const set = fn => rawSet(produce(fn));
  return {
    set,
    forms: {},
  };
});

export default function useForm(id, onSubmit)
{
  useEffect(() =>
  {
    store.getState().set(s =>
    {
      s.forms[id] = onSubmit;
    });
    return () => store.getState().set(s =>
    {
      delete s.forms[id];
    });
  }, [id, onSubmit]);
}

export function NestedForms()
{
  const forms = store(s => s.forms);
  return Object.entries(forms).map(([id, onSubmit]) => <form key={id} id={id} onSubmit={onSubmit} />);
}
