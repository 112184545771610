import fscreen from 'fscreen';
import { useMemo, useCallback, useRef } from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const className = {
  base: 'modal-content',
  afterOpen: '',
  beforeClose: '',
};
const overlayClassName = {
  base: 'modal-overlay',
  afterOpen: 'modal-open',
  beforeClose: 'modal-closing',
};

export async function commitFields(e, doSubmit)
{
  if (e.currentTarget.getAttribute('tabindex') == null) throw new Error('Form needs a tabindex');
  const active = document.activeElement;
  e.currentTarget.focus();
  active && await new Promise(resolve => window.setTimeout(resolve));
  await doSubmit();
  active?.focus();
}

export function commitFieldsOnEnter(submitRef)
{
  return e => e.key === 'Enter' && commitFields(e, () => {}).then(() => submitRef.current());
}

function parentSelector()
{
  return fscreen.fullscreenElement || document.body;
}

fscreen.addEventListener('fullscreenchange', () =>
{
  const parent = parentSelector();
  for (const element of [...document.getElementsByClassName("ReactModalPortal")])
  {
    parent.appendChild(element);
  }
});

function Modal({children, className: extraClassName, onSubmit, onRequestClose, onFocus, ...props})
{
  const theClassName = !extraClassName
    ? className
    : {
      base: `${className.base} ${extraClassName}`,
      afterOpen: '',
      beforeClose: '',
    };
  const doSubmit = useRef();
  doSubmit.current = onSubmit;
  const submit = useCallback(async e =>
  {
    e.preventDefault();
    e.stopPropagation();
    await commitFields(e, async () => await doSubmit.current(e) && onRequestClose());
  }, [onRequestClose]);
  const contentElement = useMemo(() => onSubmit
    ? (props, children) => <form onFocus={onFocus} {...props} onSubmit={submit}>{children}</form>
    : (props, children) => <div onFocus={onFocus} {...props}>{children}</div>,
    [onSubmit, submit, onFocus]);

  return <ReactModal
    {...props}
    parentSelector={parentSelector}
    onRequestClose={onRequestClose}
    className={theClassName}
    overlayClassName={overlayClassName}
    bodyOpenClassName="overflow-hidden"
    closeTimeoutMS={200}
    contentElement={contentElement}>
    {children}
  </ReactModal>;
}

export default Modal;
