import PhoneIPhone from "../icons/PhoneIPhone";

export default function AuthenticationFactor({factor, className = 'bg-shade-600', Component = 'div', children, ...props})
{
  return <Component className={`${className} rounded flex flex-col w-32 min-h-32 items-center text-center p-2`} {...props}>
    <AuthenticationFactorIcon factor={factor} />
    <b className="leading-tight my-auto">{factor.name}</b>
    {children}
  </Component>
}

export function AuthenticationFactorIcon({factor})
{
  // type twilio-totp, no alternatives at the moment
  return <Totp className="mt-auto" />;
}

export function Totp({className})
{
  return <div className={className}>
    <PhoneIPhone className="w-8 h-8 flex-shrink-0" />
    <div className="text-xs font-normal">TOTP</div>
  </div>;
}
