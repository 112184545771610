import produce from "immer";
import { useHistory } from "react-router-dom";
import create from "zustand";
import utf8 from "utf8";
import base64 from "base-64";
import { useEffect, useState } from "react";
import { parse, stringify } from "./json";
import { unstable_batchedUpdates } from "react-dom";
import { getJsonPoster, resToJson } from "./data";
import merge from 'deepmerge';
import { DateTime } from "luxon";
import { bigNumber } from "../shared/gui";
import { toast } from "react-toastify";

export const sessionChannel = window.BroadcastChannel && new BroadcastChannel('session');
if (window.BroadcastChannel) new BroadcastChannel('session').addEventListener('message', e =>
{
  if (typeof e.data !== 'object') return;
  //eslint-disable-next-line default-case
  switch (e.data.msg)
  {
    case 'login':
      if (!useGui.getState().authToken[0]) useGui.getState().authToken[1]();
      break;

    case 'logout':
      useGui.getState().authToken[1]();
      break;
  }
});

export const getCookie = name => document.cookie.match(`(?:^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';
export const rawParseAuthToken = cookie => parse(utf8.decode(base64.decode(cookie.split('.').pop())));
function parseAuthToken(cookie)
{
  try
  {
    if (!cookie) return null;
    const token = rawParseAuthToken(cookie);
    token.per = Object.freeze(new Set(token.per));
    token.cc = token.cc && Object.freeze(new Set(token.cc));
    try
    {
      useGui.getState().settings[1]({});
    }
    catch
    {
      // can't access useGui while initializing it
      // no need to clear settings in this case, so ignore
    }

    token?.id && loadSettings();
    return Object.freeze(token);
  }
  catch
  {
    return null;
  }
}

let set;
const useGui = create(function(rawSet)
{
  set = fn => rawSet(produce(fn));
  function setter(key, doSet)
  {
    doSet = doSet || set;
    return v => doSet(s => { s[key][0] = v; });
  }

  function localStorageSetter(key)
  {
    return v =>
    {
      set(s => { s[key][0] = v; });
      window.localStorage.setItem(key, stringify(v));
    };
  }

  function handleKey(e)
  {
    set(s => { s.shiftKey = e.shiftKey; });
  }

  window.addEventListener('keydown', handleKey);
  window.addEventListener('keyup', handleKey);

  const fields = {
    authToken: [parseAuthToken(getCookie('login')), cb => set(s =>
    {
      const authToken = parseAuthToken(getCookie('login'));
      s.authToken[0] = authToken;
      cb?.(authToken);
    })],
    shiftKey: false,
    inlineMenu: (stored => stored !== null ? parse(stored) : [
      {label: 'Cloud Connect', value: '/cloud-connect'},
      {label: 'Claim', value: '/claim'},
    ])(window.localStorage.getItem('inlineMenu')),
    presenterFeedback: [{}, fn => set(s => fn(s.presenterFeedback[0]))],
  };

  const simpleFields = {
    globalMessage: null,
    sidebar: null,
    sidebarProps: null,
    brandingLogo: null,
    selectedEndpointGroup: null,
    selectedEndpointGroupCols: 'followSidebar',
    selectedPresenterConnection: null,
    webrtcMuted: false,
    connectionsShowRightbar: false,
    connectionsShowDetails: [[]],
    connectionsHighlightConnection: null,
    connectionsHighlightEndpoint: null,
    connectionsRightbarTab: null,
    routerShowRightbar: false,
    settings: {},
    stripeElementProps: null,
    newPaymentMethodId: null,
    shopCurrentOrder: null,
    ftpEndpointId: null,
    subscriptionTypes: new Set(),
    limits: {},
    webrtcShowPresenter: false,
    showDisabledRouters: false,
  };

  const localStorageFields = {
    notificationRead: {},
    notificationDismissed: {},
  };

  for (const key of ['notificationRead', 'notificationDismissed'])
  {
    const value = window.localStorage.getItem(key);
    if (value === 'null' || value === 'true' || value === 'false') window.localStorage.removeItem(key);
  }

  for (let key in simpleFields)
  {
    const field = simpleFields[key];
    const [defaultValue, doSet] = Array.isArray(field) ? field : [field];
    fields[key] = [defaultValue, setter(key, doSet)];
  }

  for (let key in localStorageFields)
  {
    const initial = localStorageFields[key];
    const stored = window.localStorage.getItem(key);
    const defaultValue = stored !== null ? parse(stored) : initial;
    fields[key] = [defaultValue, localStorageSetter(key)];
  }

  return fields;
});

async function loadSettings()
{
  try
  {
    const settings = await window.fetch('/api/user/settings').then(resToJson);
    useGui.getState().settings[1](settings);
  }
  catch (e)
  {
    if (e === null) return;
    console.error('Could not load settings, retrying...', e);
    window.setTimeout(loadSettings, 2000);
  }
}

if (useGui.getState().authToken[0] && useGui.getState().authToken[0].id) loadSettings();

export function updateSettings(patch)
{
  const [settings, setSettings] = useGui.getState().settings;
  setSettings(merge(settings, patch));
  getJsonPoster('/api/user/settings', 'PATCH')(patch).then(resToJson).then(setSettings).catch(() => {});
}

export function useSidebar(component, props)
{
  const [, setSidebar] = useGui(s => s.sidebar);
  const [, setSidebarProps] = useGui(s => s.sidebarProps);
  useEffect(function()
  {
    setSidebar(component);
    setSidebarProps(props);
    return setSidebar;
  }, [component, props, setSidebar, setSidebarProps]);
}

export function useInlineMenu(label, {exact = undefined, path = undefined, admin = undefined} = {})
{
  const history = useHistory();
  useEffect(() => set(s =>
  {
    const value = path || history.location.pathname;
    const menu = s.inlineMenu;
    const index = menu.findIndex(item => item.value === value);

    if (index >= 0)
    {
      if (label === useInlineMenu.DELETE) menu.splice(index, 1);
      return;
    }

    if (label === useInlineMenu.DELETE) return;

    const len = menu.unshift({label, value, exact, admin});
    menu.splice(5, len);
    window.localStorage.setItem('inlineMenu', stringify(menu));
  }),
  // eslint-disable-next-line
  []);
}

useInlineMenu.DELETE = Symbol('delete inline menu item');
useInlineMenu.bringToFront = function(value)
{
  const menu = useGui.getState().inlineMenu;
  const index = menu.findIndex(item => item.value === value);
  if (index < 0) return;

  unstable_batchedUpdates(() => set(s => { s.inlineMenu.unshift(s.inlineMenu.splice(index, 1)[0]);  }));
}

export const entryToOption = e => ({value: e[0], label: e[1].name});

export default useGui;

export {bigNumber};
export function renderBigNumber(value, unit = 'B', digits = 3, base = 1024, className, Tag = 'div')
{
  const big = bigNumber(value, unit, digits, base);
  return <Tag className={className}>{big.value} <span className="text-sm">{big.unit}</span></Tag>;
}

export const scrollElementIntoView = el => el && el.scrollIntoView({block: 'nearest', behavior: 'smooth'});

const rootClassName = document.getElementById('root').className;
export function useRootClassName(className)
{
  useEffect(() =>
  {
    document.getElementById('root').className = `${rootClassName} ${className}`;
    return () => document.getElementById('root').className = rootClassName;
  }, [className]);
}

const getToday = () => DateTime.utc().startOf('day');
export function useToday()
{
  const [today, setToday] = useState(getToday());

  useEffect(() =>
  {
    const midnight = DateTime.utc().plus({days: 1}).startOf('day');
    const timeout = window.setTimeout(() => setToday(getToday()), midnight.diffNow().toMillis());
    return () => window.clearTimeout(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [today]);

  return today;
}

const currencyDisplayOptions = {style: 'currency', currency: 'USD'};
export function renderCurrency(cents)
{
  return (cents / 100).toLocaleString([], currencyDisplayOptions);
}

export async function copyToClipboard(name, value)
{
  try
  {
    await navigator.clipboard.writeText(value);
    toast.success(`${name} copied to clipboard!`);
  }
  catch
  {
    toast.success('Could not copy to clipboard');
  }
}
