import produce from "immer";
import { unstable_batchedUpdates } from "react-dom";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import create from "zustand";
import useGui, { sessionChannel } from "./gui";
import { parse, stringify } from "./json";
import { toast } from "react-toastify";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { useImmer } from "use-immer";

// eslint-disable-next-line
let setImmediate;
let setOutside;

export let onFetch = {};
export const fetch = function(...args)
{
  onFetch.fn && onFetch.fn();
  return window.fetch(...args).then(res =>
  {
    sessionChannel?.postMessage({msg: 'login'});
    return res;
  });
}

const useData = create(function(rawSet)
{
  const set = fn => rawSet(produce(fn));
  setImmediate = set;
  setOutside = fn => unstable_batchedUpdates(() => set(fn));

  return {
    permissions: getLookupStore('permissions', () => fetch('/api/permissions')),
    routes:      getLookupStore('routes',      () => fetch('/api/routes')),

    downloads: getTableStore('downloads', () => fetch('/api/downloads')),

    company:               getTableStore('company',               () => fetch('/api/company')),
    users:                 getTableStore('users',                 () => fetch('/api/company/users'),                getJsonPoster('/api/company/users'),                getDeleter('/api/company/users')),
    roles:                 getTableStore('roles',                 () => fetch('/api/company/roles'),                getJsonPoster('/api/company/roles'),                getDeleter('/api/company/roles')),
    endpoints:             getTableStore('endpoints',             () => fetch('/api/company/endpoints'),            getJsonPoster('/api/company/endpoints'),            getDeleter('/api/company/endpoints')),
    appliances:            getTableStore('appliances',            () => fetch('/api/company/appliances'),           getJsonPoster('/api/company/appliances'),           getDeleter('/api/company/appliances')),
    reporters:             getTableStore('reporters',             () => fetch('/api/company/reporters'),            getJsonPoster('/api/company/reporters'),            getDeleter('/api/company/reporters')),
    encoders:              getTableStore('encoders',              () => fetch('/api/company/encoders'),             getJsonPoster('/api/company/encoders'),             getDeleter('/api/company/encoders')),
    recorders:             getTableStore('recorders',             () => fetch('/api/company/recorders'),            getJsonPoster('/api/company/recorders'),            getDeleter('/api/company/recorders')),
    endpointGroups:        getTableStore('endpointGroups',        () => fetch('/api/company/endpoint-groups'),      getJsonPoster('/api/company/endpoint-groups'),      getDeleter('/api/company/endpoint-groups')),
    configurations:        getTableStore('configurations',        () => fetch('/api/company/configurations'),       getJsonPoster('/api/company/configurations'),       getDeleter('/api/company/configurations')),
    webrtcGroups:          getTableStore('webrtcGroups',          () => fetch('/api/company/webrtc-groups'),        getJsonPoster('/api/company/webrtc-groups'),        getDeleter('/api/company/webrtc-groups')),
    turnServers:           getTableStore('turnServers',           () => fetch('/api/company/turn-servers'),         getJsonPoster('/api/company/turn-servers'),         getDeleter('/api/company/turn-servers')),
    apiTokens:             getTableStore('apiTokens',             () => fetch('/api/company/api-tokens'),           getJsonPoster('/api/company/api-tokens'),           getDeleter('/api/company/api-tokens')),
    subscriptions:         getTableStore('subscriptions',         () => fetch('/api/company/subscriptions'),        getJsonPoster('/api/company/subscriptions')),
    subscriptionCodes:     getTableStore('subscriptionCodes',     () => fetch('/api/company/subscription-codes'),   getJsonPoster('/api/company/subscription-codes')),
    articles:              getTableStore('articles',              () => fetch('/api/shop/articles')),
    cart:                  getTableStore('cart',                  () => fetch('/api/shop/cart'),                    getJsonPoster('/api/shop/cart'),                    undefined,                                getUpdater('/api/shop/cart')),
    orders:                getTableStore('orders',                () => fetch('/api/shop/orders')),
    paymentMethods:        getTableStore('paymentMethods',        () => fetch('/api/shop/payment-methods'),         getJsonPoster('/api/shop/payment-methods'),         getDeleter('/api/shop/payment-methods')),
    billingDetails:        getTableStore('billingDetails',        () => fetch('/api/shop/billing-details'),         getJsonPoster('/api/shop/billing-details')),
    shopSettings:          getTableStore('shopSettings',          () => fetch('/api/shop/settings'),                getJsonPoster('/api/shop/settings')),
    cloudConnectTokens:    getTableStore('cloudConnectTokens',    () => fetch('/api/company/cloud-connect-tokens'), getJsonPoster('/api/company/cloud-connect-tokens'), getDeleter('/api/company/cloud-connect-tokens')),
    authenticationFactors: getTableStore('authenticationFactors', () => fetch('/api/user/authentication-factors'),  getJsonPoster('/api/user/authentication-factors'),  getDeleter('/api/user/authentication-factors')),

    turnQuotaTransactions: getTableStore('turnQuotaTransactions', (from, to) => fetch([
      '/api/company/turn-quota/transactions',
      [from && `from=${from}`, to && `to=${to}`].filter(Boolean).join('&'),
    ].filter(Boolean).join('?'))),
    broadcastQuotaTransactions: getTableStore('broadcastQuotaTransactions', (from, to) => fetch([
      '/api/company/broadcast-quota/transactions',
      [from && `from=${from}`, to && `to=${to}`].filter(Boolean).join('&'),
    ].filter(Boolean).join('?'))),

    rolesLookup:          getLookupStore('rolesLookup',          () => fetch('/api/company/roles/lookup')),
    endpointsLookup:      getLookupStore('endpointsLookup',      () => fetch('/api/company/endpoints/lookup')),
    appsLookup:           getLookupStore('appsLookup',           () => fetch('/api/company/apps/lookup')),
    configurationsLookup: getLookupStore('configurationsLookup', () => fetch('/api/company/configurations/lookup')),
    turnServersLookup:    getLookupStore('turnServersLookup',    () => fetch('/api/company/turn-servers/lookup')),
    usersLookup:          getLookupStore('usersLookup',          () => fetch('/api/company/users/lookup')),
    simpleTokensLookup:   getLookupStore('simpleTokensLookup',   () => fetch('/api/company/simple-tokens-templates/lookup')),

    companies:           getTableStore('companies',           () => fetch('/api/admin/companies'),       getJsonPoster('/api/admin/companies'),       getDeleter('/api/admin/companies')),
    apps:                getTableStore('apps',                () => fetch('/api/admin/apps'),            getJsonPoster('/api/admin/apps'),            getDeleter('/api/admin/apps')),
    usersAdmin:          getTableStore('usersAdmin',          () => fetch('/api/admin/users'),           getJsonPoster('/api/admin/users'),           getDeleter('/api/admin/users')),
    rolesAdmin:          getTableStore('rolesAdmin',          () => fetch('/api/admin/roles'),           getJsonPoster('/api/admin/roles'),           getDeleter('/api/admin/roles')),
    endpointsAdmin:      getTableStore('endpointsAdmin',      () => fetch('/api/admin/endpoints'),       getJsonPoster('/api/admin/endpoints'),       getDeleter('/api/admin/endpoints')),
    appliancesAdmin:     getTableStore('appliancesAdmin',     () => fetch('/api/admin/appliances'),      getJsonPoster('/api/admin/appliances'),      getDeleter('/api/admin/appliances')),
    reportersAdmin:      getTableStore('reportersAdmin',      () => fetch('/api/admin/reporters'),       getJsonPoster('/api/admin/reporters'),       getDeleter('/api/admin/reporters')),
    encodersAdmin:       getTableStore('encodersAdmin',       () => fetch('/api/admin/encoders'),        getJsonPoster('/api/admin/encoders'),        getDeleter('/api/admin/encoders')),
    recordersAdmin:      getTableStore('recordersAdmin',      () => fetch('/api/admin/recorders'),       getJsonPoster('/api/admin/recorders'),       getDeleter('/api/admin/recorders')),
    endpointGroupsAdmin: getTableStore('endpointGroupsAdmin', () => fetch('/api/admin/endpoint-groups'), getJsonPoster('/api/admin/endpoint-groups'), getDeleter('/api/admin/endpoint-groups')),
    configurationsAdmin: getTableStore('configurationsAdmin', () => fetch('/api/admin/configurations'),  getJsonPoster('/api/admin/configurations'),  getDeleter('/api/admin/configurations')),
    webrtcGroupsAdmin:   getTableStore('webrtcGroupsAdmin',   () => fetch('/api/admin/webrtc-groups'),   getJsonPoster('/api/admin/webrtc-groups'),   getDeleter('/api/admin/webrtc-groups')),
    turnServersAdmin:    getTableStore('turnServersAdmin',    () => fetch('/api/admin/turn-servers'),    getJsonPoster('/api/admin/turn-servers'),    getDeleter('/api/admin/turn-servers')),
    tokenRevocations:    getTableStore('tokenRevocations',    () => fetch('/api/admin/token-revocations')),
    passwordResetRequests:getTableStore('passwordResetRequests',() => fetch('/api/admin/password-reset-requests')),
    apiTokensAdmin:      getTableStore('apiTokensAdmin',      () => fetch('/api/admin/api-tokens'),      getJsonPoster('/api/admin/api-tokens'),      getDeleter('/api/admin/api-tokens')),
    articlesAdmin:       getTableStore('articlesAdmin',       () => fetch('/api/admin/articles'),        getJsonPoster('/api/admin/articles'),        getDeleter('/api/admin/articles')),
    ordersAdmin:         getTableStore('ordersAdmin',         () => fetch('/api/admin/orders'),          getJsonPoster('/api/admin/orders')),
    notifications:       getTableStore('notifications',       () => fetch('/api/admin/notifications'),   getJsonPoster('/api/admin/notifications'),   getDeleter('/api/admin/notifications')),
    jobs:                getTableStore('jobs',                () => fetch('/api/admin/jobs')),
    reports:             getTableStore('reports',             () => fetch('/api/admin/reports')),

    rolesLookupAdmin:       getLookupStore('rolesLookupAdmin',       () => fetch('/api/admin/roles/lookup')),
    endpointsLookupAdmin:   getLookupStore('endpointsLookupAdmin',   () => fetch('/api/admin/endpoints/lookup')),
    companiesLookupAdmin:   getLookupStore('companiesLookupAdmin',   () => fetch('/api/admin/companies/lookup')),
    usersLookupAdmin:       getLookupStore('usersLookupAdmin',       () => fetch('/api/admin/users/lookup')),

    createSubscriptionCodes: getLookupStore('createSubscriptionCodes', getJsonPoster('/api/admin/subscription-codes', 'POST', x => x)),
    createQuotaCodes:        getLookupStore('createQuotaCodes',        getJsonPoster('/api/admin/quota-codes', 'POST', x => x)),
    searchSubscriptionCodes: getLookupStore('searchSubscriptionCodes', (mode, search) => fetch(`/api/admin/subscription-codes/${mode}/${encodeURIComponent(search)}`)),
    searchQuotaCodes:        getLookupStore('searchQuotaCodes',        (mode, search) => fetch(`/api/admin/quota-codes/${mode}/${encodeURIComponent(search)}`)),
  };
});

export async function resToJson(res)
{
  if (!res.ok)
  {
    if (res.status === 401)
    {
      unstable_batchedUpdates(useGui.getState().authToken[1]);
      // don't set the error when we just need to log in
      // eslint-disable-next-line
      throw null;
    }

    throw await res.text().then(parse);
  }

  return res.text().then(parse);
}

function getTableStore(key, fetchFn, saveFn, deleteFn, updateFn)
{
  return {
    data: null,
    loading: false,
    fetch: getFetcher(key, fetchFn),
    save: saveFn,
    delete: deleteFn,
    update: updateFn,
    add: getAdder(key),
    remove: getRemover(key),
    clear: getClearer(key),
  };
}

function getLookupStore(key, fetchFn)
{
  return {
    data: null,
    loading: false,
    fetch: getFetcher(key, fetchFn),
    clear: getClearer(key),
  };
}

// eslint-disable-next-line
function getApi(key, saveFn)
{
  return {
    save: saveFn,
    // add: getNestedAdder(key),
  };
}

function getFetcher(key, fetchFn)
{
  let isLoading = false;
  return function(...args)
  {
    if (isLoading) return;

    isLoading = true;
    setOutside(s => { s[key].loading = true; s[key].error = false; });
    fetchFn.apply(null, args)
      .then(resToJson)
      .then(data => setOutside(s => { s[key].data = data; s[key].loading = isLoading = false; }))
      .catch(e => setOutside(s => { s[key].loading = isLoading = false; s[key].error = e; }));
  };
}

export function getJsonPoster(url, method = 'POST', postProc = resToJson)
{
  return data => fetch(url, data === undefined ? {method} : {method, body: stringify(data), headers: {'Content-Type': 'application/json'}}).then(postProc);
}

function getDeleter(url)
{
  return obj => fetch(`${url}/${obj.id || obj}`, {method: 'DELETE'}).then(resToJson);
}

function getUpdater(url)
{
  return (obj, data) => fetch(`${url}/${obj.id || obj}`, {method: 'PATCH', body: stringify(data), headers: {'Content-Type': 'application/json'}}).then(resToJson);
}

function getAdder(key)
{
  return function(obj)
  {
    setOutside(s =>
    {
      if (s[key].data)
      {
        const i = s[key].data.findIndex(o => o.id === obj.id);
        if (i >= 0) s[key].data[i] = obj;
        else s[key].data.push(obj);
      }
      else
      {
        s[key].data = [obj];
      }
    });
  }
}

function getRemover(key)
{
  return function(obj)
  {
    setOutside(s =>
    {
      if (!s[key].data) return;

      const id = obj.id || obj;
      const i = s[key].data.findIndex(o => o.id === id);
      if (i >= 0) s[key].data.splice(i, 1);
    });
  }
}

function getClearer(key)
{
  return function()
  {
    setOutside(s =>
    {
      s[key].data = null;
      s[key].error = null;
    });
  }
}

const acceptsImageType = t => t.startsWith('image/');
const acceptsImageFile = f => acceptsImageType(f.type);
export function useImageUpload(onUploaded)
{
  return useUpload('/api/company/images', onUploaded, acceptsImageType, acceptsImageFile);
}

export function useUpload(url, onUploaded, acceptsType, acceptsFile, onDropRejected)
{
  const abortController = useRef();
  const [isUploading, setIsUploading] = useState();
  const [{isOver, canDrop}, dropRef] = useDrop(() => ({
    accept: NativeTypes.FILE,
    drop: async item =>
    {
      const file = item.files.find(acceptsFile);
      if (!file) return onDropRejected?.();
      try
      {
        if (abortController.current) abortController.current.abort();
        setIsUploading(true);
        abortController.current = new AbortController();
        const res = await fetch(url, {
          method: 'POST',
          body: file,
          headers: {
            'X-BirdDog-FileName': file.name,
          },
          signal: abortController.current.signal,
        }).then(resToJson);
        abortController.current = null;
        onUploaded(res);
      }
      catch (e)
      {
        if (e.name !== 'AbortError') toast.error(e.message || e.toString());
      }
      finally
      {
        setIsUploading();
      }
    },
    canDrop: item =>
    {
      try
      {
        for (const f of item.items) if (acceptsType(f.type)) return true;
        return false;
      }
      catch (e)
      {
        return true;
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }), [onUploaded, setIsUploading, acceptsFile, acceptsType, onDropRejected, url]);

  useEffect(() => () => abortController.current && abortController.current.abort(), []);

  const abort = useCallback(() =>
  {
    if (abortController.current)
    {
      abortController.current.abort();
      abortController.current = null;
      return true;
    }

    return false;
  }, [abortController]);

  return {isUploading, isOver, canDrop, dropRef, abort};
}

export function useFetch(url, method = 'GET')
{
  const [data, setData] = useImmer({});

  const reload = useMemo(function()
  {
    let isLoading = false;
    return () =>
    {
      if (isLoading) return;

      isLoading = true;
      setData(s => { s.loading = true; s.error = false; });
      getJsonPoster(url, method)()
        .then(data => setData(s => { s.data = data; s.loading = isLoading = false; }))
        .catch(e => setData(s => { s.loading = isLoading = false; s.error = e; }));
    };
  }, [url, method, setData]);

  return [data, reload];
}

useData.set = setOutside;
export default useData;
