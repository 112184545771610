import fastJson from 'fast-json-stringify';
import sjson from 'secure-json-parse';
import formatHighlight from 'json-format-highlight';
import { useMemo } from "react";

const colorOptions = {
  keyColor: 'var(--color-blue-200)',
  numberColor: 'var(--color-green-300)',
  stringColor: 'var(--color-red-400)',
  trueColor: 'var(--color-blue-400)',
  falseColor: 'var(--color-blue-400)',
  nullColor: 'var(--color-purple-400)',
};

export const stringify = fastJson({});
export const parse = sjson.parse;
const cleanStyleRegex = /word-wrap:break-word;white-space:pre-wrap;/g;
export const pretty = value => formatHighlight(value, colorOptions).replace(cleanStyleRegex, '');
export const usePretty = value => useMemo(() => value && { __html: pretty(value)}, [value]);
export function tryParse(json, defaultValue)
{
  try
  {
    return sjson.parse(json);
  }
  catch
  {
    return defaultValue;
  }
}
