import { usePopper } from "react-popper";
import { useState, useEffect, useMemo, useCallback, useRef, Fragment } from "react";
import { createPortal } from "react-dom";
import fscreen from "fscreen";

const fn = {};
const hideTooltip = () => fn.hideTooltip && fn.hideTooltip();

function wrapFunctions(defaultFn, additionalFn)
{
  return additionalFn
    ? (...args) => { defaultFn(...args); additionalFn(...args); }
    : defaultFn;
}

export function useTooltip(tip, color, placement, offset, onMouseEnter, onMouseLeave)
{
  return useMemo(() => tip ? {
    onMouseEnter: wrapFunctions(e => fn.showTooltip && fn.showTooltip(e.currentTarget, tip, color, placement, offset), onMouseEnter),
    onMouseLeave: wrapFunctions(hideTooltip, onMouseLeave),
  } : {}, [tip, color, placement, offset, onMouseEnter, onMouseLeave]);
}

export function Tooltip({tip, children})
{
  return <div {...useTooltip(tip)}>{children}</div>;
}

const roundTransformXRegex = /(translate3?d?\(-?\d+)(?:\.\d*)?(?=px)/;

function filterPopperStyle(style)
{
  if (style.transform)
  {
    style = Object.assign({}, style);
    style.transform = style.transform.replace(roundTransformXRegex, '$1');
  }

  return style;
}

export default function DropDownMenu({className, menuClassName, buttonContent, right, items, renderItem, openFnRef})
{
  const reference = useRef();

  const onClick = useCallback(e =>
  {
    if (e)
    {
      e.preventDefault();
      e.stopPropagation();
    }

    fn.showDropDown && fn.showDropDown(reference.current, items, renderItem, right, menuClassName);
  }, [items, renderItem, right, menuClassName]);
  if (openFnRef) openFnRef.current = onClick;

  return <button type="button" className={`${className} outline-none`} onClick={onClick} onContextMenu={onClick}>
    <div className="relative">
      {buttonContent}
      <div className="absolute bottom-0 left-1/2" ref={reference} />
    </div>
  </button>;
}

DropDownMenu.renderButtonItem = function renderButtonItem(item)
{
  return <button type="button" className={`w-full ${item.className}`} key={item.label} onClick={item.onClick}>{item.label}</button>;
}

function doHideDropDown(e)
{
  !e.letPass && e.preventDefault();
  fn.hideDropDown && fn.hideDropDown();
}

const dropDownOffset = ({placement}) => placement.startsWith('bottom') ? [0, 12] : [0, 20];
export function PopperContainer()
{
  const [tip, setTip] = useState({});
  const [tipReference, setTipReference] = useState(null);
  const [tipPopper, setTipPopper] = useState(null);
  const [tipArrow, setTipArrow] = useState(null);
  const tipUsePopper = usePopper(tipReference, tipPopper, {
    modifiers: [
      {name: 'offset', options: {offset: [0, tip.offset == null ? 4 : tip.offset]}},
      {name: 'arrow', options: {element: tipArrow, padding: 5}},
    ],
    placement: tip.placement || 'top',
    strategy: 'fixed',
  });

  const [dropDown, setDropDown] = useState({});
  const [dropDownReference, setDropDownReference] = useState(null);
  const [dropDownPopper, setDropDownPopper] = useState(null);
  const dropUseDownPopper = usePopper(dropDownReference, dropDownPopper, {
    modifiers: [{name: 'offset', options: {offset: dropDownOffset}}],
    placement: dropDown.right ? 'bottom-start' : 'bottom-end',
    strategy: 'fixed',
  });
  const popperPortal = useMemo(() => document.getElementById('popper-portal'), []);

  useEffect(() =>
  {
    fn.showTooltip = (reference, tip, color, placement, offset) =>
    {
      (fscreen.fullscreenElement || document.body).appendChild(popperPortal);
      setTip({tip, color, placement, offset});
      setTipReference(reference);
    };
    fn.hideTooltip = () =>
    {
      setTip({});
      setTipReference(null);
    };
    fn.showDropDown = (reference, items, renderItem, right, menuClassName) =>
    {
      (fscreen.fullscreenElement || document.body).appendChild(popperPortal);
      setDropDown({items, renderItem, right, menuClassName});
      setDropDownReference(reference);
    };
    fn.hideDropDown = () =>
    {
      setDropDown({});
      setDropDownReference(null);
    };
  }, [popperPortal]);

  useEffect(() =>
  {
    dropDown.items && dropDownPopper && dropDownPopper.focus();
  }, [dropDownPopper, dropDown.items]);

  const cleanItems = useMemo(() => dropDown.items && dropDown.items.map((sub, i) => Object.assign(sub.filter(Boolean), {i})).filter(sub => sub.length), [dropDown.items]);

  return createPortal(<>
    <div className={`tooltip ${tip.color || 'bg-black text-white'} ${tip.tip ? 'block' : ''}`} ref={setTipPopper} style={tipUsePopper.styles.popper} {...tipUsePopper.attributes.popper}>
      <div className="arrow" ref={setTipArrow} style={tipUsePopper.styles.arrow} {...tipUsePopper.attributes.arrow} />
      {tip.tip}
    </div>

    <div className={`absolute inset-0 w-screen h-screen ${dropDown.items ? 'block' : 'hidden'}`} onClick={doHideDropDown} onContextMenu={doHideDropDown}>
      <div className={`cursor-auto ${dropDown.items ? 'block' : 'hidden'}`} tabIndex="-1" ref={setDropDownPopper} style={filterPopperStyle(dropUseDownPopper.styles.popper)} {...dropUseDownPopper.attributes.popper}>
        <ul className={`menu ${dropDown.right && 'right'} ${dropDown.menuClassName || ''}`}>
          {cleanItems?.map((sub, i) => <Fragment key={sub.i}>
            {!!i && <hr />}
            {sub.map(dropDown.renderItem)}
          </Fragment>)}
        </ul>
      </div>
    </div>
  </>, popperPortal);
}
