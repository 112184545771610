import { useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import Button from './components/Button';
import BirdDogLogo from './icons/BirdDogLogo';
import CloudConnect from './icons/CloudConnect';
import useGui, { sessionChannel } from './utils/gui';
import { stringify } from "./utils/json";
import { version } from '../package.json';
import { Link } from 'react-router-dom';
import AuthenticationFactor from './components/AuthenticationFactor';

export default function Login()
{
  return <div className="modal-open">
    <Helmet><title>Login</title></Helmet>
    <LoginPrompt className="modal-content"/>
    <div className="text-xs text-shade-700 text-center select-text absolute w-full bottom-0 left-0 font-light">version {version}</div>
  </div>;
}

export function LoginPrompt({className, endpointIntegration})
{
  const [authToken] = useGui(s => s.authToken);
  const submit = useRef();
  const doSubmit = async e =>
  {
    e.preventDefault();
    submit.current?.(e);
  };

  return <form className={`${className} w-full top-1/3 ${endpointIntegration ? 'text-shade-400' : 'max-w-xl rounded-xl'} bg-shade-700`} onSubmit={doSubmit}>
    <div className="border-b border-shade-800 flex font-bold p-2 pt-2.5 pb-1.5">
      <BirdDogLogo className="h-6 text-bd w-auto mr-1.5" /> • Login
    </div>
    <div className="p-5 flex flex-col gap-4 w-full max-w-xl m-auto">
      <div className="border-4 border-green-800 w-24 h-24 rounded-lg flex flex-shrink-0 self-center">
        <CloudConnect className="w-16 h-16 text-bd m-auto" />
      </div>

      {!authToken || !authToken.per.has('2fa')
        ? <FirstFactor submit={submit} {...{endpointIntegration}} />
        : <SecondFactor submit={submit} />}
    </div>
  </form>;
}

function FirstFactor({submit, endpointIntegration})
{
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState();
  const [, updateAuthToken] = useGui(s => s.authToken);

  submit.current = async function tryLogin(e)
  {
    const userName = e.target.birdDogCloudUserName.value.trim();
    if (!userName) return e.target.birdDogCloudUserName.focus();
    const password = e.target.birdDogCloudPassword.value;
    if (!password) return e.target.birdDogCloudPassword.focus();
    const stayLoggedIn = e.target.birdDogCloudStayLoggedIn?.checked;

    setLoggingIn(true);
    setError();
    try
    {
      const res = await window.fetch('/api/login', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: stringify({userName, password, stayLoggedIn}),
      });
      const data = await res.json();
      if ((data && data.error) || !res.ok) throw data;
      window.setTimeout(() => updateAuthToken(authToken => authToken && !authToken.per.has('2fa') && sessionChannel?.postMessage({msg: 'login'})), 1);
    }
    catch (e)
    {
      setError(e && e.error && e.message ? e : { message: 'Error communicating with server, please try again later.', e });
    }
    finally
    {
      setLoggingIn(false);
    }
  }

  return <>
    <div className="flex flex-col xs:grid grid-cols-2-auto xs:gap-2">
      <label className="w-full" htmlFor="birdDogCloudUserName">Username/Email</label>
      <input type="text" className="mb-4 xs:m-0" name="birdDogCloudUserName" id="birdDogCloudUserName" autoFocus />
      <label className="w-full" htmlFor="birdDogCloudPassword">Password</label>
      <input type="password" className="mb-4 xs:m-0" name="birdDogCloudPassword" id="birdDogCloudPassword" />
      {!endpointIntegration && <>
        <div className="hidden xs:block" />
        <label className="mr-auto flex gap-1 items-center"><input type="checkbox" name="birdDogCloudStayLoggedIn" /> Stay logged in</label>
      </>}
    </div>
    {error && <div className="text-red-300 bg-red-900 text-sm rounded p-2">
      <div className="font-bold">Login Error:</div>
      {error.message}
      {error.e && process.env.NODE_ENV === 'development' && <div className="text-yellow-100 text-xs">{error.e.message}</div>}
    </div>}
    <div className="flex gap-2 justify-end">
      {!endpointIntegration && <Link to={{pathname: '/request-password-reset', state: {backBack: true}}} className="btn btn-secondary">Forgot password</Link>}
      <Button primary submit disabled={loggingIn}>Login</Button>
    </div>
  </>;
}

const selectedButtonProps = {
  className: '',
  primary: true,
};
const nonDigitRegex = /\D+/g;
function SecondFactor({submit})
{
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState();
  const [authToken, updateAuthToken] = useGui(s => s.authToken);
  const [selectedFactorId, setSelectedFactorId] = useState();

  const factors = authToken?.fct;
  const selectedFactor = factors?.length === 1 ? factors[0] : factors?.find(factor => factor.id === selectedFactorId);

  submit.current = async function tryLogin(e)
  {
    e.preventDefault();

    if (!selectedFactor) return;

    const input = e.target.birdDogCloudTotpCode;
    const code = input.value.replace(nonDigitRegex, '');

    if (!code)
    {
      input.select();
      input.focus();
      return;
    }

    setLoggingIn(true);
    setError();
    try
    {
      const res = await window.fetch(`/api/user/authentication-factors/validate/${selectedFactor.type}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: stringify({id: selectedFactor.id, code}),
      });
      const data = await res.json();
      if ((data && data.error) || !res.ok) throw data;
      window.setTimeout(() =>
      {
        updateAuthToken();
        sessionChannel?.postMessage({msg: 'login'});
      }, 1);
    }
    catch (e)
    {
      setError(e && e.error && e.message ? e : { message: 'Error communicating with server, please try again later.', e });
      input.select();
      input.focus();
    }
    finally
    {
      setLoggingIn(false);
    }
  }

  async function logout()
  {
    try
    {
      const res = await window.fetch('/api/logout', {method: 'POST'});
      const data = await res.json();
      if (data.error || !res.ok) throw data;
    }
    catch
    {
    }
    finally
    {
      document.cookie = `login=;path=/;domain=${process.env.REACT_APP_DOMAIN}`;
      window.setTimeout(updateAuthToken, 1);
    }
  }

  return <>
    <SecondFactorForm {...{factors, error, setSelectedFactorId, selectedFactor}} />
    <div className="flex gap-2">
      <button type="button" className="text-primary text-sm ml-auto" onClick={logout}>Back to password entry</button>
      <Button primary submit disabled={loggingIn}>Login</Button>
    </div>
  </>;
}

export function SecondFactorForm({factors, error, setSelectedFactorId, selectedFactor, onReload})
{
  const codeInput = useRef();

  function selectFactor(factorId)
  {
    setSelectedFactorId(factorId);
    if (codeInput.current)
    {
      codeInput.current.disabled = false;
      codeInput.current.focus();
    }
  }

  return <>
    <div className="flex flex-col gap-2 items-center text-center">
      <p>Your account is protected by two factor authentication.</p>
      {factors?.length > 1 && <>
        <p>Please choose a factor and enter the code displayed.</p>
        <div className="grid grid-cols-auto-32 gap-2 justify-center w-full text-shade-300">
          {factors.map(factor => <AuthenticationFactor key={factor.id} factor={factor} onClick={() => selectFactor(factor.id)} Component={Button} {...(factor.id === selectedFactor?.id && selectedButtonProps)} />)}
        </div>
      </>}
      <label className="w-full text-center" htmlFor="birdDogCloudTotpCode">
        {factors?.length === 1
          ? <>Please enter the code displayed on <span className="text-shade-100">{selectedFactor?.name}</span></>
          : 'Code'}
      </label>
      <input ref={codeInput} type="text" className="text-2xl font-bold mx-auto w-32 text-center" name="birdDogCloudTotpCode" id="birdDogCloudTotpCode" disabled={!selectedFactor} autoFocus />
    </div>
    {error && <div className="text-red-300 bg-red-900 text-sm rounded p-2">
      <div className="font-bold">Login Error:</div>
      {error.message}
      {error.e && process.env.NODE_ENV === 'development' && <div className="text-yellow-100 text-xs">{error.e.message}</div>}
    </div>}
  </>;
}
