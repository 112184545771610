// source SVG file is in src/icons for <select> CSS

export default function EllipsisV(props)
{
  return <svg {...props} width="2.05mm" height="8.8612mm" version="1.1" viewBox="0 0 2.05 8.8612" xmlns="http://www.w3.org/2000/svg" fill="currentcolor">
    <path d="m1.02 2.05c-0.56621 0-1.0252-0.45897-1.0252-1.0252 0-0.56585 0.45896-1.0248 1.0252-1.0248 0.56586 0 1.0248 0.45896 1.0248 1.0248 0 0.56621-0.45896 1.0252-1.0248 1.0252"/>
    <path d="m1.02 5.46c-0.56621 0-1.0252-0.45897-1.0252-1.0252 0-0.56585 0.45896-1.0248 1.0252-1.0248 0.56586 0 1.0248 0.45897 1.0248 1.0248 0 0.56621-0.45896 1.0252-1.0248 1.0252"/>
    <path d="m1.02 8.86c-0.56621 0-1.0252-0.45896-1.0252-1.0252 0-0.56586 0.45896-1.0248 1.0252-1.0248 0.56586 0 1.0248 0.45896 1.0248 1.0248 0 0.56621-0.45896 1.0252-1.0248 1.0252"/>
  </svg>;
}
