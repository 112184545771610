import { lazy, Suspense } from "react";
// import pMinDelay from 'p-min-delay';
import ErrorBoundary from "../components/ErrorBoundary";
import { Helmet } from "react-helmet-async";
import Button from "../components/Button";
// import LoadingIndicator from "../components/LoadingIndicator";

const pages = new Map();

function ErrorContent({resetErrorBoundary, error})
{
  if ((error instanceof TypeError && error.message === 'modules[moduleId] is undefined') || error.name === 'ChunkLoadError') return window.location.reload();

  return <>
    <Helmet><title>Page crashed</title></Helmet>
    <h1 className="text-3xl">This page has crashed :(</h1>
    <h2 className="text-2xl">We're so sorry</h2>
    <div className="ml-4">
      <p className="text-red-600 block"><b>{error.name}</b>: {error.message}</p>
      {error && process.env.NODE_ENV === 'development' && <>
        <p>Here's some more info, maybe it'll help?</p>
        {error.stack && <samp className="text-red-800 text-xs block whitespace-pre-wrap">{error.stack}</samp>}
      </>}
    </div>
    {resetErrorBoundary && <Button onClick={resetErrorBoundary} primary className="mt-4">Reload page</Button>}
  </>;
}

export function Error(props)
{
  return <Suspense fallback=""><ErrorContent {...props} /></Suspense>
}

function LoadingContent({children})
{
  return <>
    <Helmet><title>Loading</title></Helmet>
    {children}
  </>;
}

export function Loading(props)
{
  return <Suspense fallback=""><LoadingContent {...props} /></Suspense>
}

export default function LazySafePage({page, ...props})
{
  let Page = pages.get(page);
  // if (!Page) pages.set(page, Page = lazy(() => pMinDelay(import(`./${page}`), 1000)));
  if (!Page) pages.set(page, Page = lazy(() => import(`./${page}`)));

  return <ErrorBoundary key={page} fallback={Error}>
    <Suspense fallback={<Loading />}>
      <Page {...props} />
    </Suspense>
  </ErrorBoundary>
}
