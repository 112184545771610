import { toast } from 'react-toastify';
import CheckCircle from '../icons/CheckCircle';
import Error from '../icons/Error';
import Info from '../icons/Info';
import Warning from '../icons/Warning';

{
  let toastPortal;
  function bringToFront()
  {
    document.body.appendChild(toastPortal || (toastPortal = document.getElementById('toast-portal')));
  }

  const toastError = toast.error;
  const errorOptions = {icon: () => <Error className="text-red-600" />};
  toast.error = (message, options) => bringToFront() || toastError(message, options ? Object.assign({}, options, errorOptions) : errorOptions);

  const toastInfo = toast.info;
  const infoOptions = {icon: () => <Info className="text-primary" />};
  toast.info = (message, options) => bringToFront() || toastInfo(message, options ? Object.assign({}, options, infoOptions) : infoOptions);

  const toastWarning = toast.warning;
  const warningOptions = {icon: () => <Warning className="text-yellow-500" />};
  toast.warning = (message, options) => bringToFront() || toastWarning(message, options ? Object.assign({}, options, warningOptions) : warningOptions);

  const toastSuccess = toast.success;
  const successOptions = {icon: () => <CheckCircle className="text-green-400" />};
  toast.success = (message, options) => bringToFront() || toastSuccess(message, options ? Object.assign({}, options, successOptions) : successOptions);
}

export function toastError(e)
{
  e && toast.error(e.message || e);
}
