import { useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import BirdDogLogo from "../icons/BirdDogLogo";
import Facebook from '../icons/Facebook';
import Instagram from '../icons/Instagram';
import Linkedin from '../icons/Linkedin';
import Twitter from '../icons/Twitter';
import Youtube from '../icons/Youtube';
import useGui from '../utils/gui';

export function useSidebarInfo()
{
  const [component] = useGui(s => s.sidebar);
  return getSidebarInfo(component);
}

export function getSidebarInfo(component)
{
  return {
    component,
    size: component ? 'w-32 md:w-1/4' : 'w-0',
  };
}

export default function Sidebar()
{
  const sidebar = useSidebarInfo();
  const [sidebarProps] = useGui(s => s.sidebarProps);
  const [brandingLogo] = useGui(s => s.brandingLogo);

  return RenderSidebar({size: sidebar.size, Component: sidebar.component, sidebarProps, LogoLinkComponent: Link, logoUrl: brandingLogo})
}

export function RenderSidebar({size, Component, sidebarProps, LogoLinkComponent, logoUrl})
{
  const [search, setSearch] = useState('');
  const [settings] = useGui(s => s.settings);
  const [authToken] = useGui(s => s.authToken);

  return <div className={`flex-shrink-0 ${size} transition-all text-shade-400`}>
    <div className={`bg-shade-700 ${size} transition-all overflow-hidden h-full fixed flex flex-col items-stretch flex-shrink-0`}>
      <LogoLinkComponent to={settings.global?.homePage || '/'} className="mt-4 mb-4 xl:mb-8 transition-all self-center">
        {logoUrl
          ? <img className="text-bd w-24 md:w-40 h-12 transition-all flex-shrink-0 object-contain" src={logoUrl} alt="Home" />
          : <BirdDogLogo className="text-bd w-24 md:w-40 h-12 transition-all flex-shrink-0" />}
      </LogoLinkComponent>
      {Component && <SearchBar className={`mx-4 xl:mx-8 transition-all flex-shrink-0 ${Component.searchable ? 'h-9' : 'h-0 overflow-hidden'}`} value={search} onChange={setSearch} />}
      <div className="flex-grow overflow-hidden flex flex-col">
        {Component && <Component search={search} {...sidebarProps} />}
      </div>
      {authToken?.id && <>
        <div className="flex justify-center flex-wrap md:flex-nowrap text-shade-300 gap-1 lg:gap-2 xl:gap-4 mt-2 flex-shrink-0">
          <a href="https://www.facebook.com/birddog.tv" target="_blank" rel="noopener noreferrer" className="flex items-center p-2 transition-colors hover:text-shade-200"><Facebook className="w-5 h-5" /></a>
          <a href="https://twitter.com/bird_dog_gear" target="_blank" rel="noopener noreferrer" className="flex items-center p-2 transition-colors hover:text-shade-200"><Twitter className="w-5 h-5" /></a>
          <div className="w-full md:hidden"></div>
          <a href="https://www.instagram.com/birddog.tv/" target="_blank" rel="noopener noreferrer" className="flex items-center p-2 transition-colors hover:text-shade-200"><Instagram className="w-5 h-5" /></a>
          <a href="https://www.youtube.com/c/BirdDogAustralia" target="_blank" rel="noopener noreferrer" className="flex items-center p-2 transition-colors hover:text-shade-200"><Youtube className="w-5 h-5" /></a>
          <a href="https://www.linkedin.com/company/birddog" target="_blank" rel="noopener noreferrer" className="flex items-center p-2 transition-colors hover:text-shade-200"><Linkedin className="w-5 h-5" /></a>
        </div>
        <a href="https://birddog.tv" target="_blank" rel="noopener noreferrer" className="flex items-center transition-colors text-shade-300 hover:text-shade-200 mx-auto mb-6 mt-2">BirdDog.tv</a>
      </>}
    </div>
  </div>;
}
