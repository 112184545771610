import { useEffect, useRef, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import DropDownMenu from "../components/Popper";
import CloudConnect from "../icons/CloudConnect";
import EllipsisV from "../icons/EllipsisV";
import Logout from "../icons/Logout";
import Security from "../icons/Security";
import Settings from "../icons/Settings";
import User from "../icons/User";
import useGui, { sessionChannel, useInlineMenu } from "../utils/gui";
import { useSidebarInfo } from "./Sidebar";
import useMainMenu from './MainMenu';
import useSc, { useDataChannel } from "../utils/socketcluster";
import Eval from "../components/Eval";
import SupervisorAccount from "../icons/SupervisorAccount";
import { toast } from "react-toastify";
import Announcement from "../icons/Announcement";
import { useTableSorter } from "../components/TableSorter";
import Help from "../icons/Help";
import KeyboardArrowLeft from "../icons/KeyboardArrowLeft";
import KeyboardArrowRight from "../icons/KeyboardArrowRight";

export default function Titlebar()
{
  const {size: sidebarSize} = useSidebarInfo();

  return RenderTitlebar({sidebarSize, renderInlineMenu});
}

const notificationsDummy = [[1]];
export function RenderTitlebar({sidebarSize, renderInlineMenu, isPreview})
{
  const inlineMenu = useRef();
  const inlineMenuIFirstHidden = useRef();
  const quickButtons0 = useRef();
  const quickButtons1 = useRef();
  const location = useLocation();

  const [authToken, updateAuthToken] = useGui(s => s.authToken);
  const [, setGlobalMessage] = useGui(s => s.globalMessage);
  const inlineMenuItems = useGui(s => s.inlineMenu);
  const socketState = useSc(s => s.socketState);

  const notifications = useNotifications(authToken?.id);
  const [{[authToken?.id]: notificationRead}] = useGui(s => s.notificationRead);
  const hasUnreadNotifications = useMemo(() => (notifications.length && !notificationRead) || notifications.some(notification => notification.published > notificationRead), [notificationRead, notifications]);
  const unreadHighPriorityNotifications = useMemo(() => notifications?.filter(notification => notification.priority && notification.published > (notificationRead || 0)), [notificationRead, notifications]);
  useDataChannel('notificationsGlobal');

  async function logout()
  {
    setGlobalMessage(authToken.las ? 'switching back...' : 'logging out...');
    try
    {
      const res = await window.fetch(authToken.las ? '/api/admin/logout-as' : '/api/logout', {method: 'POST'});
      const data = await res.json();
      if (data.error || !res.ok) throw data;
    }
    catch
    {
    }
    finally
    {
      if (!authToken.las) document.cookie = `login=;path=/;domain=${process.env.REACT_APP_DOMAIN}`;
      window.setTimeout(() => sessionChannel?.postMessage({msg: 'logout'}), 1);
      window.setTimeout(updateAuthToken, 1);
      setGlobalMessage();
      if (authToken.las)
      {
        toast.success('User changed');
        window.location.reload();
      }
    }
  }

  function handleResize()
  {
    const menu = inlineMenu.current;
    if (menu)
    {
      menu.parentNode.classList.remove('hidden');
      menu.parentNode.classList.add('overflow-hidden');
      const buttons = menu.childNodes;
      for (const button of buttons) button.classList.remove('hidden');
      const menuRect = menu.getBoundingClientRect();
      const rights = Array.prototype.map.call(buttons, button => Math.floor(button.getBoundingClientRect().right - menuRect.left));
      let iBringToFront = null;
      inlineMenuIFirstHidden.current = buttons.length;
      for (let i = buttons.length - 1; i > 0; --i)
      {
        if (rights[i] <= menuRect.width) break;

        inlineMenuIFirstHidden.current = i;
        if (buttons[i].classList.contains('btn-primary')) iBringToFront = i;
      }

      if (iBringToFront !== null) useInlineMenu.bringToFront(buttons[iBringToFront].getAttribute('href'));
      if (rights[0] > menuRect.width) menu.parentNode.classList.add('hidden');
      else for (let i = buttons.length - 1; i >= inlineMenuIFirstHidden.current; --i) buttons[i].classList.add('hidden');
      menu.parentNode.classList.remove('overflow-hidden');
    }

    for (const buttons of [quickButtons0.current, quickButtons1.current])
    {
      if (buttons)
      {
        buttons.classList.remove('w-4');
        buttons.classList.remove('flex-shrink-0');
        if (buttons.childNodes[0].offsetTop > 0) // check the first one 'cause we're wrapping reversed
        {
          buttons.classList.add('w-4');
          buttons.classList.add('flex-shrink-0');
        }
      }
    }
  }

  useEffect(function()
  {
    let timeout;
    function onResize()
    {
      if (timeout) window.clearTimeout(timeout);
      timeout = window.setTimeout(handleResize, 10);
    }

    window.addEventListener('resize', onResize);
    return function()
    {
      window.removeEventListener('resize', onResize);
      window.clearTimeout(timeout);
    };
  }, [inlineMenu]);
  useEffect(function()
  {
    handleResize();
    const interval = window.setInterval(handleResize, 30);
    const stop = () => window.clearInterval(interval);
    window.setTimeout(stop, 220);
    return stop;
  }, [sidebarSize]);
  useEffect(() => handleResize(), [location, inlineMenu]);

  const mainMenuItems = useMainMenu();

  return <>
    {!!unreadHighPriorityNotifications?.length && <NotificationNagBar notifications={unreadHighPriorityNotifications} />}
    <header className="h-16 flex-shrink-0 z-10">
      <div className="h-16 w-full fixed flex left-0 pointer-events-none">
        <div className={`flex-shrink-0 transition-all ${sidebarSize}`} />
        <div className="bg-shade-800 w-full min-w-0 px-4 xl:px-8 transition-all flex items-center pointer-events-auto">
          <div className={`mr-auto border-2 ${socketState === 'open' ? 'border-green-800' : socketState === 'connecting' ? 'border-yellow-600 border-opacity-70' : 'border-red-900'} bg-gray-800 transition-colors w-10 h-10 rounded-md flex flex-shrink-0`}>
            <CloudConnect className={`w-7 h-7 m-auto transition-colors ${socketState === 'open' ? 'text-bd' : socketState === 'connecting' ? 'text-yellow-400 text-opacity-70' : 'text-red-700'}`} />
          </div>


          <div className="p-1 -mr-1">
            <div className="flex gap-1 gap-y-4 h-8 relative whitespace-nowrap" ref={inlineMenu}>
              {renderInlineMenu(inlineMenuItems, inlineMenuIFirstHidden, isPreview)}
            </div>
          </div>

          <DropDownMenu
            className="text-shade-300 hover:text-shade-200 focus:text-shade-200 transition-colors mx-2 xl:mx-4 px-2 py-0.5"
            menuClassName={isPreview ? 'transform origin-top-right scale-50 menu-preview' : 'harrald'}
            buttonContent={<EllipsisV className="h-5 my-0.5" />}
            items={mainMenuItems}
            renderItem={isPreview ? renderPreviewItem : useMainMenu.renderItem}
            />

          <Eval Component={isPreview ? 'div' : NavLink} className="flex flex-wrap content-end h-10 overflow-hidden mr-auto" to="/me">
            {authToken?.las
              ? <div className="rounded-full w-10 h-10 bg-red-800 text-red-400 flex overflow-hidden flex-shrink-0 mr-3">
                <SupervisorAccount className="w-10 h-10 m-auto" />
              </div>
              : <div className="rounded-full w-10 h-10 bg-shade-500 text-shade-100 flex overflow-hidden flex-shrink-0 mr-3">
                <User className="w-10 h-10 m-auto" />
              </div>}
            <div className="leading-5 text-shade-500 whitespace-nowrap overflow-hidden">
              <div className="font-bold overflow-ellipsis overflow-hidden">{authToken?.nam}</div>
              <div className="overflow-ellipsis overflow-hidden">{authToken?.cna}</div>
            </div>
          </Eval>

          <div className="flex flex-wrap-reverse ml-2 xl:ml-4 gap-x-2 xl:gap-x-4 gap-y-1 relative" ref={quickButtons0}>
            {!!notifications.length && <DropDownMenu
              className="text-shade-300 hover:text-shade-200 focus:text-shade-200 transition-colors relative"
              buttonContent={<>
                <Announcement className="w-6 h-6 min-w-4 max-w-full" />
                {hasUnreadNotifications && <div className="w-3 h-3 p-0.5 absolute -top-0.5 -right-0.5 rounded-full bg-shade-800">
                  <div className="w-2 h-2 rounded-full bg-red-500 animate-ping" />
                  <div className="w-2 h-2 rounded-full bg-red-500 -mt-2" />
                </div>}
              </>}
              items={notificationsDummy}
              renderItem={renderNotification} />}
            <Eval Component={isPreview ? 'div' : 'a'} href="https://birddog.cloud/resources" target="_blank" rel="noreferrer" className="text-shade-300 hover:text-shade-200 focus:text-shade-200 transition-colors"><Help className="w-6 h-6 min-w-4 max-w-full" /></Eval>
          </div>
          <div className="flex flex-wrap-reverse ml-2 xl:ml-4 gap-x-2 xl:gap-x-4 gap-y-1 relative" ref={quickButtons1}>
            <Eval Component={isPreview ? 'div' : NavLink} to="/settings" className="text-shade-300 hover:text-shade-200 focus:text-shade-200 transition-colors"><Settings className="w-6 h-6 max-w-full" /></Eval>
            <Eval Component={isPreview ? 'div' : 'button'} type="button" className="text-shade-300 hover:text-shade-200 focus:text-shade-200 transition-colors" onClick={isPreview ? undefined : logout}><Logout className="w-6 h-6 max-w-full" /></Eval>
          </div>

        </div>
      </div>
    </header>
  </>;
}

function useNotifications(userId)
{
  const [{[userId]: notificationDismissed}] = useGui(s => s.notificationDismissed);
  const allNotifications = useSc(s => s.notificationsGlobal);
  return useMemo(() =>
  {
    if (!allNotifications) return [];
    if (!notificationDismissed) return allNotifications;
    return allNotifications.filter(notification => notification.priority || notification.published > notificationDismissed);
  }, [notificationDismissed, allNotifications]);
}

const renderPreviewItem = item => <div {...item} key={item.to} />;
function renderNotification()
{
  return <Notifications key="1" />;
}

const clickNotification = e => !e.letPass && e.stopPropagation();
const comparePublished = useTableSorter.getMappedCompare(n => -n.published, useTableSorter.compareInt);
function Notifications()
{
  const [authToken] = useGui(s => s.authToken);
  const allNotifications = useNotifications(authToken.id);
  const [notifications, dismissible] = useMemo(() =>
  {
    const notifications = allNotifications.slice().sort(comparePublished);
    const dismissible = notifications.findLast(n => !n.priority);
    return [notifications, dismissible?.id];
  }, [allNotifications]);

  useEffect(() => () =>
  {
    const [allNotificationRead, setNotificationRead] = useGui.getState().notificationRead;
    const notificationRead = allNotificationRead[authToken.id] ||'';
    const maxRead = Math.max(...notifications.map(notification => notification.published));
    if (maxRead > notificationRead) setNotificationRead({...allNotificationRead, [authToken.id]: maxRead});
  }, [notifications, authToken.id]);

  return notifications.map(notification =>
  {
    function dismissNotification(e)
    {
      e.letPass = notifications.length === 1;
      const [allNotificationDismissed, setNotificationDismissed] = useGui.getState().notificationDismissed;
      setNotificationDismissed({...allNotificationDismissed, [authToken.id]: notification.published});
    }

    return <div key={notification.id} className="max-w-xl first:border-0 border-t border-shade-600" onClick={clickNotification}>
      <div className="font-bold text-left whitespace-normal mb-2">{notification.heading}</div>
      <div className="text-sm whitespace-pre-line text-justify">{notification.text}</div>
      <div className="text-xs flex text-bd-light">
        {notification.readMoreUrl && <a href={notification.readMoreUrl} target="_blank" rel="noreferrer">Read more...</a>}
        {notification.id === dismissible && <button type="button" className="ml-auto" onClick={dismissNotification}>Dismiss</button>}
      </div>
    </div>;
  });
}

export function renderInlineMenu(inlineMenuItems, inlineMenuIFirstHidden, isPreview)
{
  return inlineMenuItems.map((item, i) => <Eval Component={isPreview ? 'div' : NavLink}
    to={location => item.value === '/connections' && location.pathname.startsWith('/connections/') ? location.pathname : item.value}
    key={item.value}
    exact={item.exact}
    className={`btn btn-secondary flex gap-1 ${i >= inlineMenuIFirstHidden.current && 'hidden'} ${isPreview && !i && 'btn-primary'}`}
    activeClassName="btn-primary"
    tabIndex={isPreview ? '-1' : undefined}>
    {item.admin && <Security className="w-4 h-4 -mt-2" />}{item.label}
  </Eval>);
}

function NotificationNagBar({notifications})
{
  const [i, setI] = useState(0);

  return <header className="bg-yellow-500 text-black p-3 flex relative">
    <div className="flex flex-col max-w-5xl mx-auto leading-tight">
      <b>{notifications[i].heading}</b>
      {notifications[i].text}
      {notifications[i].readMoreUrl && <a href={notifications[i].readMoreUrl} className="text-xs underline" target="_blank" rel="noreferrer">Read more...</a>}
    </div>
    {notifications?.length > 1 && <>
      <button type="button" onClick={() => setI(i => (i + 1) % notifications.length)}><KeyboardArrowLeft /></button>
      <span className="text-xs my-auto">{i + 1}/{notifications.length}</span>
      <button type="button" onClick={() => setI(i => (i - 1 + notifications.length) % notifications.length)}><KeyboardArrowRight /></button>
    </>}
    <div className="absolute right-2 bottom-0 text-2xs">Dismiss this bar by clicking the flashing System Messages button below</div>
  </header>;
}
