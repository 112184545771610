// eslint-disable-next-line
'use strict';

const prefixes = ['', 'K', 'M', 'G', 'T', 'P', 'E'];
module.exports =
{
  bigNumber(value, unit = 'B', digits = 3, base = 1024)
  {
    const abs = Math.abs(value);
    const pow = Math.min(Math.floor((abs >= 1 ? Math.log(abs) : 0) / Math.log(base)), prefixes.length - 1);

    return {
      value: (value / Math.pow(base, pow)).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: digits}),
      unit: `${prefixes[pow]}${unit}`
    };
  },
  bigNumbers(values, unit = 'B', digits = 3, base = 1024)
  {
    const abs = Math.abs(Math.max.apply(null, values));
    const pow = Math.min(Math.floor((abs >= 1 ? Math.log(abs) : 0) / Math.log(base)), prefixes.length - 1);

    return {
      values: values.map(value => (value / Math.pow(base, pow)).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: digits})),
      unit: `${prefixes[pow]}${unit}`
    };
  },
  formatBigNumber(value, unit = 'B', digits = 3, base = 1024)
  {
    const big = module.exports.bigNumber(value, unit, digits, base);
    return `${big.value} ${big.unit}`;
  },
};
