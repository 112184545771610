import { toast } from "react-toastify";
import useGui, { getCookie, rawParseAuthToken, sessionChannel } from "./gui";

const warningTime = 120 * 1000;
let logoutWarningTimeout;
let logoutTimeout;
let logoutToastId;
if (window.BroadcastChannel) new BroadcastChannel('session').addEventListener('message', e =>
{
  if (typeof e.data !== 'object') return;
  //eslint-disable-next-line default-case
  switch (e.data.msg)
  {
    case 'login':
    case 'logout':
      checkLogoutWarning();
      break;
  }
});

function checkLogoutWarning(clockOffset)
{
  try
  {
    const token = rawParseAuthToken(getCookie('login'));
    if (!token?.exp) return;

    const now = new Date().getTime();
    clockOffset = clockOffset !== undefined ? clockOffset : token.iat * 1000 - now;
    const timeRemaining = token.exp * 1000 - now - clockOffset - 2000;
    const timeToWarning = timeRemaining - warningTime;
    window.clearTimeout(logoutWarningTimeout);
    window.clearTimeout(logoutTimeout);
    logoutWarningTimeout = null;
    logoutTimeout = null;
    dismissLogoutToast(timeToWarning > 0);
    if (timeToWarning > 0)
    {
      logoutWarningTimeout = window.setTimeout(() =>
      {
        checkLogoutWarning(clockOffset);
      }, timeToWarning);
    }
    else
    {
      popLogoutToast(warningTime - timeToWarning);
      logoutTimeout = window.setTimeout(() =>
      {
        document.cookie = `login=;path=/;domain=${process.env.REACT_APP_DOMAIN}`;
        sessionChannel?.postMessage({msg: 'logout'});
        window.setTimeout(useGui.getState().authToken[1], 10);
        dismissLogoutToast();
      }, timeRemaining + 1000);
    }
  }
  catch {}
}

async function renewSession()
{
  window.clearTimeout(logoutTimeout);
  logoutTimeout = null;
  if (!useGui.getState().authToken[0] || !logoutToastId) return;
  logoutToastId = null;
  try
  {
    await fetch('/api/renew-session');
    sessionChannel?.postMessage({msg: 'login'});
  }
  catch {}
  finally
  {
    useGui.getState().authToken[1]();
  }
}

function popLogoutToast(warningRemaining)
{
  const poppedAt = new Date().getTime();
  logoutToastId = toast.info(<>
      <div>Your session is about to expire, you will be logged out soon.</div>
      <div className="text-xs">Click or swipe this notification to renew.</div>
    </>, {
    autoClose: false,
    hideProgressBar: false,
    pauseOnFocusLoss: false,
    progress: 0.999999,
    onClose: renewSession,
    onClick: renewSession,
  });
  const interval = window.setInterval(() =>
  {
    const timeSincePop = new Date().getTime() - poppedAt;
    const remaining = warningRemaining - timeSincePop;
    if (remaining < 0 || !logoutToastId) return window.clearInterval(interval);
    toast.update(logoutToastId, {
      progress: Math.min(remaining / warningTime, 0.999999),
      progressStyle: {transition: `transform .2s linear 0s`},
    });
  }, 250);
}

function dismissLogoutToast(success)
{
  if (logoutToastId)
  {
    toast.dismiss(logoutToastId);
    if (success) toast.success('Session renewed', {autoClose: 1000, pauseOnFocusLoss: false});

    logoutToastId = null;
  }
}
