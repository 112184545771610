import { Helmet } from "react-helmet-async";
import { useInlineMenu } from "../utils/gui";

function ForceRemount()
{
  useInlineMenu(useInlineMenu.DELETE);
  return null;
}

export default function NotFound({location})
{
  return <>
    <Helmet><title>Not Found</title></Helmet>
    <h1 className="text-3xl">Not Found</h1>
    <p>There's nothing there at <samp>{location.pathname}</samp></p>
    <ForceRemount key={location.pathname} />
  </>;
}
