import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import useGui from "../utils/gui";

export default function useMainMenu()
{
  const per = useGui(s => s.authToken[0]?.per);

  return useMemo(() => per && [[
      {to: '/connections', children: 'Connections'},
      {to: '/matrix', children: 'Matrix'},
      {to: '/router', children: 'Router'},
      {to: '/cloud-connect', children: 'Cloud Connect'},
      {to: '/presenter-control', children: 'Presenter Control'},
      // {to: '/encoders', children: 'Encoders'},
    ], [
      per.has('company') && per.has('endpoints') && {to: '/claim', children: 'Claim'},
      {to: '/downloads', children: 'Downloads'},
      per.has('shop') && {to: '/shop', children: 'Shop'},
      {href: 'https://birddog.cloud/resources', children: 'Need Assistance?', target: '_blank', rel: 'noreferrer'},
    ], [
      per.has('company') && {to: '/company', children: 'Company'},
    ], [
      per.has('admin') && {to: '/admin', children: 'Admin'},
    ], [
      per.has('doc') && {to: '/documentation', children: 'Documentation'},
      per.has('admin') && per.has('dev') && per.has('doc') && {to: '/validation-test', children: 'Validation Test'},
    ],
  ], [per]);
}

const letPass = e => e.letPass = true;

useMainMenu.renderItem = item => item.href
// eslint-disable-next-line jsx-a11y/anchor-has-content
  ? <a {...item} onClick={letPass} key={item.href} />
  : <NavLink {...item} key={item.to} />;
