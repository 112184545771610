export default function Button({ primary, tertiary, danger, success, submit, className, ...props })
{
  return <button
    type={submit ? 'submit' : 'button'}
    {...props}
    className={`btn ${primary
      ? 'btn-primary'
      : danger
        ? 'btn-danger'
        : success
          ? 'btn-success'
          : tertiary
            ? 'btn-tertiary'
            : 'btn-secondary'} ${className}`} />;
}
